.navbar {
  background: linear-gradient(
      45deg,
      rgba(86, 58, 250, 0.9) 0%,
      rgba(116, 15, 214, 0.9) 100%
    ),
    center center no-repeat;
}

.navbar-brand {
  color: white;
}

.left {
  color: white;
  padding-left: 20px;
}

.icon {
  color: white;
  text-decoration-color: white;
}

.icon:hover {
  color: #f5a623;
}

.nav-link {
  color: white;
  font-family: "Open Sans", sans-serif;
}

.nav-link:hover {
  color: #fff;
}

.logo {
  font-size: 24px;
  margin: 0;
  padding: 10px 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.logo:hover {
  color: #fff;
}

a.left {
  position: relative;
}

a.left:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

a.left:hover:before {
  visibility: visible;
  width: 100%;
}

.navbar-toggler-icon {
  color: white;
}

.fa-bars {
  color: white;
  font-size: 28px;
}

/* Get Startet Button */
.get-started-btn {
  margin-left: 25px;
  color: #fff;
  border-radius: 5px;
  padding: 6px 25px 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.get-started-btn:hover {
  border-color: #fff;
  color: #fff;
}

@media (max-width: 992px) {
  .get-started-btn {
    margin: 0 48px 0 0;
    padding: 6px 18px;
  }
}
