.login {
  margin-top: 6rem;
}

.login-title {
  text-align: center;
  font-family: "open-sans,sans-serif";
  font-weight: bold;
  margin-top: 1.5rem;
  color: #000;
}

.label-input {
  font-family: "open-sans,sans-serif";
}

.login-desc {
  text-align: center;
  font-family: "open-sans,sans-serif";
  font-weight: 700;
  color: #000;
}

.login-form {
  background-color: white;
  border: 3px solid whitesmoke;
  border-radius: 1.5rem;
}

.verification-card {
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  border: 3px solid #fff;
  border-bottom-left-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
}

.input-form {
  align-items: center;
  justify-content: center;
}

.login-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-signup-info {
  text-align: right;
  font-family: "open-sans,sans-serif";
  color: white;
}

.login-form-footer-info {
  text-align: right;
  word-wrap: inherit;
  font-family: "open-sans,sans-serif";
  color: black;
  padding-bottom: 1rem;
}

.login-input {
  margin: auto;
}

.btn-login-submit {
  background-color: #5a5af3;
}

@media screen and (max-width: 768px) {
  .login-form {
    border: none;
  }

  .login-img-container {
    border-bottom-left-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    margin-top: 3rem;
  }
}
